import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/frontend/node_modules/.pnpm/next-view-transitions@0.3.0_next@14.2.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_reac_3rzuwbkkcrhsdqlhcuzsgmzeyy/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/filepond@4.31.1/node_modules/filepond/dist/filepond.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayout"] */ "/vercel/path0/frontend/packages/component/src/root-layout/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ProjectDefender"] */ "/vercel/path0/frontend/src/components/section/project-defender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider","AuthReady"] */ "/vercel/path0/frontend/src/hooks/auth.tsx");
